<template>
  <div class="main">
    <!-- body start -->
    <div class="bodybox">
      <div class="textbox">
        <div class="flow">
          <div class="flow_item">
            <div class="item_left">
              <span>1</span>
            </div>
            <div class="item_right">
              <div class="right_top">选择所需的服务</div>
              <div class="right_bottom">点击服务详细页查看</div>
            </div>
          </div>
          <div class="flow_item">
            <div class="item_left">
              <span>2</span>
            </div>
            <div class="item_right">
              <div class="right_top">线下签订服务合同</div>
              <div class="right_bottom">服务商上门签订合同</div>
            </div>
          </div>
          <div class="flow_item">
            <div class="item_left">
              <span>3</span>
            </div>
            <div class="item_right">
              <div class="right_top">进行服务</div>
              <div class="right_bottom">服务商向您提供服务</div>
            </div>
          </div>
          <div class="flow_item">
            <div class="item_left">
              <span>4</span>
            </div>
            <div class="item_right">
              <div class="right_top">验收评价</div>
              <div class="right_bottom">验收服务并评价服务质量</div>
            </div>
          </div>
        </div>
      </div>
      <div class="textbox">
        <div class="give">
          <div class="fl_title">企业供需互动</div>
          <div class="flex1">
            <span :class="InterType == '' ? 'active1' : ''" @click="choese('')"
              >全部</span
            >
            <span
              :class="InterType == '0' ? 'active1' : ''"
              @click="choese('0')"
              >供应</span
            >
            <span
              :class="InterType == '1' ? 'active1' : ''"
              @click="choese('1')"
              >需求</span
            >
          </div>
          <div class="fl_list" v-for="(item, i) in InteractionList" :key="i">
            <div class="list_title" @click="getGinfor(item.SysID)">
              <span style="color: #000" v-if="item.Type == 0">
                <img
                  style="
                    display: inline-block;
                    height: 32px;
                    width: 32px;
                    padding: 0 10px 0 0;
                    vertical-align: middle;
                  "
                  class=""
                  src="../assets/img/gong.png"
                />
              </span>
              <span style="color: #000" v-if="item.Type == 1">
                <img
                  style="
                    display: inline-block;
                    height: 32px;
                    width: 32px;
                    padding: 0 10px 0 0;
                    vertical-align: middle;
                  "
                  class=""
                  src="../assets/img/xu.png"
              /></span>
              {{ item.Title }}
            </div>
            <div class="list_cont">
              {{ item.Intro }}
            </div>
            <div class="list_time">
              <span class="commpn">{{ item.CompanyName }}</span>
         
              <span class="times">{{ item.PublishTime }}</span>
                   <span
              class="times"
                style="color: #ff6633;"
                v-if="item.Type == 0"
                >报价：{{ item.Money=='面议'?item.Money:(item.Money+'元') }}</span
              >
              <span
              class="times"
                style="color: #ff6633; "
                v-if="item.Type == 1"
                >预算：{{ item.Money=='面议'?item.Money:(item.Money+'元') }}</span
              >
            </div>
          </div>
          <div class="more" v-if="IsShowMore == 1">
            <span @click="GetPageMore()"
              >加载更多 <i class="el-icon-d-arrow-right"></i
            ></span>
          </div>
          <div class="more" v-if="IsShowMore != 1 && InteractionList.length">
            <em>已加载全部 </em>
          </div>
          <div class="more" v-if="IsShowMore != 1 && !InteractionList.length">
            <em>暂无数据 </em>
          </div>
        </div>
      </div>
    </div>
    <!-- body end -->
  </div>
</template>

<script>
// 公共样式

import timeFormat from "../util/time.js";
import "../assets/css/common.css";
export default {
  data() {
    return {
      height: "",
      count: 10,
      loading: false,
      // activeIndex: '4',
      drawer: false,
      IsShowMore: 1,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      navlist: [
        {
          names: "首页",
          path: "/",
          id: "1",
        },
        {
          names: "政策资讯",
          path: "/news",
          id: "2",
        },
        {
          names: "产业载体",
          path: "/carrier",
          id: "3",
        },
        {
          names: "企业互动",
          path: "/interaction",
          id: "4",
        },
        {
          names: "金融服务",
          path: "/service",
          id: "5",
        },
        {
          names: "企业服务",
          path: "/enterpriseServices",
          id: "6",
        },
        {
          names: "招聘服务",
          path: "/recruit",
          id: "7",
        },
      ],
      arr: ["zt1", "zt2", "zt3", "zt4", "zt5", "zt6", "zt7", "zt8"],
      InteractionList: [],
      PageSize:10,
      PageIndex:1,
      InterType:''
    }
  },
  computed: {
    noMore() {
      return this.count >= 20;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },

  methods: {
    choese(e) {
      this.InterType = e;
      this.PageIndex = 1;
      this.InteractionList = [];
      this.GetInteractionList();
    },
    getGinfor(item) {
      this.$router.push({ path: "/Gdetile", query: { SysID: item } });
    },
    getXinfor(item) {
      this.$router.push({ path: "/Xdetile", query: { SysID: item } });
    },
    GetPageMore() {
      this.PageIndex = this.PageIndex + 1;
      this.GetInteractionList();
    },
    GetInteractionList() {
      let that = this;
      that
        .$post("/api/Interaction/List", {
          State: "true",
          Type: that.InterType,
          Cstate: true,
          Sort: "CreateOn desc",
          PageSize: that.PageSize,
          PageIndex: that.PageIndex,
        })
        .then(function (data) {
          data.Obj.forEach((v) => {
            v.PublishTime = timeFormat.timeFormat(v.PublishTime, 2);
            that.InteractionList.push(v);
          });

          if (data.Obj.length == that.PageSize) {
            that.IsShowMore = 1;
          } else {
            that.IsShowMore = 0;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    load() {
      this.loading = true;
      setTimeout(() => {
        this.count += 2;
        this.loading = false;
      }, 2000);
    },
    handleSelect(e) {
      let that = this;
      console.log(e);
      that.$router.push(e);
      setTimeout(() => {
        that.drawer = false;
      }, 500);
    },
    getlogin() {
      this.$router.push("/login");
    },
    register() {
      this.$router.push("/register");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  mounted() {
    var that = this;
    that.GetInteractionList();
    let windowWidth = that.windowWidth;
    console.log(windowWidth);
    if (windowWidth < 900 && windowWidth > 400) {
      that.height = "200px";
    } else if (windowWidth < 400) {
      that.height = "150px";
    }
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; // 高
        that.windowWidth = window.fullWidth; // 宽
      })();
    };
  },
};
</script>

<style scoped lang="scss">
.list {
  max-height: 200px;
}
// 内容
.textbox {
  width: 1400px;
  margin-left: 50%;
  transform: translateX(-50%);
  // background-color: #475669;
  padding-top: 30px;
  overflow: hidden;
}
.flow {
  display: flex;
  width: 100%;
  background-color: #f2f2f2;
  height: 100px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  .flow_item {
    flex: 1;
    display: flex;
    height: 100px;
    // background-color: red;
    .item_left {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        height: 30px;
        line-height: 30px;
        text-align: center;
        width: 30px;
        border-radius: 50%;
        border: 1px solid #717071;
        font-weight: 500;
        color: #717071;
        font-size: 16px;
      }
    }
    .item_right {
      flex: 2;
      height: 100%;
      display: flex;
      flex-direction: column;

      justify-content: center;
      .right_top {
        font-size: 16px;

        color: #666;
      }
      .right_bottom {
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        color: #ccc;
      }
    }
  }
}
// 供需列表
.give {
  float: left;
  padding: 30px;
  box-sizing: border-box;

  // background-color: yellow;
}
.have {
  float: left;
  box-sizing: border-box;
  padding: 30px;

  width: 50%;

  // background-color: yellow;
}
.fl_title {
  height: 30px;
  padding-left: 10px;
  box-sizing: border-box;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  border-left: 5px solid rgb(63, 188, 187);
  // color: #bb0007;
}
.fl_list {
  height: 160px;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
  padding: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  width: 48%;
  display: inline-block;
  margin: 10px;
  .list_title {
    height: 35px;
    line-height: 35px;
    font-size: 18px;
    cursor: pointer;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
  .list_cont {
    height: 50px;
    line-height: 25px;
    font-size: 14px;
    color: #666;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .list_time {
    height: 35px;
    line-height: 35px;
    
    .commpn {
      float: left;
      color: rgb(54, 108, 179);
      font-size: 16px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      width: 50%;
    }
    .times {
      float: right;
      margin-right: 10px;
      font-size: 14px;
      max-width: 120px;
      color: rgb(218, 214, 214);
       text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
    }
  }
}

.aaa {
  display: none;
}


@media (max-width: 500px) {

  .flow {
  display: flex;
  width: 100%;
  background-color: #f2f2f2;
  height: 100px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  .flow_item {
    flex: 1;
    display: flex;
    height: 100px;
    // background-color: red;
    .item_left {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        height: 20px;
        line-height: 20px;
        text-align: center;
        width: 20px;
        border-radius: 50%;
        border: 1px solid #717071;
        font-weight: 500;
        color: #717071;
        font-size: 16px;
      }
    }
    .item_right {
      flex: 2;
      height: 100%;
      display: flex;
      flex-direction: column;

      justify-content: center;
      .right_top {
        font-size: 12px;

        color: #666;
      }
      .right_bottom {
        display: none;
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        color: #ccc;
      }
    }
  }
}
  .textbox {
  width: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  // background-color: #475669;
  padding-top: 0;
  overflow: hidden;
}
  // 供需列表
.give {
  float: left;
  padding: 0;
  box-sizing: border-box;
width: 100%;
  // background-color: yellow;
}
.have {
  float: left;
  box-sizing: border-box;
  padding: 30px;

  width: 50%;

  // background-color: yellow;
}
.fl_title {
  height: 30px;
  padding-left: 10px;
  box-sizing: border-box;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  border-left: 5px solid rgb(63, 188, 187);
  // color: #bb0007;
}

  .fl_list {
    height: 160px;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 4px;
    width: 96%;
    display: inline-block;
    margin: 10px 10px 10px 2%;
    .list_title {
      height: 35px;
      line-height: 35px;
      font-size: 17px;
      cursor: pointer;
      font-weight: 700;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
    .list_cont {
      height: 50px;
      line-height: 25px;
      font-size: 12px;
      color: #666;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .list_time {
      height: 35px;
      line-height: 35px;
      .commpn {
        display: none;
        float: left;
        color: rgb(54, 108, 179);
        font-size: 14px;
      }
      .times {
        float: left;
        font-size: 14px;
        color: #555;
      }
    }
  }
}
</style>
